
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.badge {
  z-index: 2;
  color: $color-accent-fresh;

  &.insideCard {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &.sale {
    background-color: $color-plush-primary;
  }

  &.top {
    background-color: $color-explorer-primary;
  }

  &.new {
    background-color: $color-success-primary;
  }

  &.coffeeOfTheMonth {
    background-color: $color-rainforest-primary;
  }

  &.freeShipping {
    background-color: $color-indigo-primary;
  }

  &.blackFriday {
    background-color: $color-accent-italian;
  }
}
