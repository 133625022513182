
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
%autoWidth {
  width: 100%;
  height: auto;
}

.productImg {
  @extend %autoWidth;
  opacity: 1;
}

.withPadding {
  padding: $space-md;
}

.hoverImage {
  @extend %autoWidth;
  display: none;
}

@include media-breakpoint-up(lg) {
  .hoverImage {
    display: block;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 2;
    transition: opacity 250ms ease-in-out 250ms;
    opacity: 0;

    &:hover {
      opacity: 1;
      transition: opacity 250ms ease-in-out 250ms;
    }
  }
}
