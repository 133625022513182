
      // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
      @import "src/styles/variables.scss";
    
.textContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: $space-xxl;
}
